import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getSolutionList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/solutions?${queryString}`)
}

export const getSolutionSearch = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/solutions/search?${queryString}`)
}

export const getSolution = (idSolution) => {
  return http.get(`api/solution/${idSolution}`)
}

export const getCertificateList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`api/solution/certificates?${queryString}`)
}

export const getSurveyList = (pagination) => {
  pagination.limit = 1000
  const queryString = createQuery(pagination)

  return http.get(`api/solution/surveys?${queryString}`)
}

export const createSolution = ({ data }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.card_image && typeof data.card_image === 'object') {
    form.append('card_image', data.card_image, data.card_image.name)
  }

  if (data.file && data.file.length > 0) {
    form.append('file', data.file[0], data.file[0].name)
  }

  if (data.podcast_file && data.podcast_file.length > 0) {
    form.append('podcast_file', data.podcast_file[0], data.podcast_file[0].name)
  }

  return http.post('api/solution', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateSolution = ({ id, data }) => {
  const form = parseToFormDataWithArray(data)

  if (data.image && typeof data.image === 'object') {
    form.append('image', data.image, data.image.name)
  }

  if (data.card_image && typeof data.card_image === 'object') {
    form.append('card_image', data.card_image, data.card_image.name)
  }

  if (data.file && data.file.length > 0) {
    form.append('file', data.file[0], data.file[0].name)
    form.delete('file_id')
  }

  if (data.podcast_file && data.podcast_file.length > 0) {
    form.append('podcast_file', data.podcast_file[0], data.podcast_file[0].name)
  }

  return http.post(`api/solution/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const removeSolution = (idSolution) => {
  return http.delete(`api/solution/${idSolution}`)
}

export const toggleActiveSolution = (idSolution) => {
  return http.post(`api/solution/${idSolution}/toggle`)
}

export const getCancellationList = () => {
  return http.get('api/cancellation_reason')
}

export const getSolutionAsset = (idAsset) => {
  return http.get(
    `api/assets/${idAsset}/file`,
    {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache'
      },
      data: {}
    }
  )
}

export const getClosedSolutionList = () => {
  const pagination = {
    query: {},
    filter: {
      session_type: 'closed'
    },
    order: { name: 'ASC' },
    page: 1,
    limit: 999999,
    lastPage: null
  }
  const queryString = createQuery(pagination)

  return http.get(`api/solutions?${queryString}`)
}
