import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getMediationPlanList = (query) => {
  const queryString = createQuery(query)

  return http.get(`api/mediation-plans?${queryString}`)
}

export const getMediationPlan = (id) => {
  return http.get(`api/mediation-plans/${id}`)
}

export const createMediationPlan = (data) => {
  const attachments = []

  if (data.mediations && data.mediations.length > 0) {
    data.mediations.map(mediation => {
      if (mediation?.mediationAsset?.length > 0) {
        const attachment = mediation.mediationAsset[0]

        if (attachment instanceof Blob) {
          mediation.attachment = attachment.name
          attachments.push(attachment)
        }
      }

      return mediation
    })
  }

  const form = parseToFormDataWithArray(data)

  attachments.forEach((attachment) => {
    form.append('attachments[]', attachment, attachment.name)
  })

  return http.post('api/mediation-plans', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const updateMediationPlan = ({ id, data }) => {
  const attachments = []

  if (data.mediations && data.mediations.length > 0) {
    data.mediations.map(mediation => {
      if (mediation?.mediationAsset?.length > 0) {
        const attachment = mediation.mediationAsset[0]

        if (attachment instanceof Blob) {
          mediation.attachment = attachment.name
          attachments.push(attachment)
        }
      }

      return mediation
    })
  }

  const form = parseToFormDataWithArray(data)

  attachments.forEach((attachment) => {
    form.append('attachments[]', attachment, attachment.name)
  })

  form.append('_method', 'PUT')

  return http.post(`api/mediation-plans/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const toggleActiveMediationPlan = (id) => {
  return http.patch(`api/mediation-plans/${id}/toggle`)
}

export const removeMediationPlan = (id) => {
  return http.delete(`api/mediation-plans/${id}`)
}

export const getLogList = (payload) => {
  const queryString = createQuery(payload.query)

  return http.get(`api/mediation-plans/${payload.id}/logs?${queryString}`)
}
