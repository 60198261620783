import * as MediationPlan from '@/api/MediationPlan'
import * as Solution from '@/api/Solution'
import * as Filter from '@/api/Filter'
import * as Showcase from '@/api/Showcase'

const requests = {
  ...MediationPlan,
  ...Solution,
  ...Filter,
  ...Showcase
}

function attemptGetMediationPlanList (pagination) {
  return requests.getMediationPlanList(pagination)
    .then(mediationPlansList => {
      return {
        data: mediationPlansList.data.data,
        firstPage: mediationPlansList.data.firstPage,
        lastPage: mediationPlansList.data.lastPage,
        nextPage: mediationPlansList.data.nextPage,
        previousPage: mediationPlansList.data.previousPage,
        actualPage: mediationPlansList.data.actualPage,
        total: mediationPlansList.data.total
      }
    })
}

function attemptGetMediationPlan (id) {
  return requests.getMediationPlan(id)
    .then(response => {
      return response.data
    })
}

function attemptCreateMediationPlan (form) {
  return requests.createMediationPlan(form)
    .then(response => {
      return response
    })
}

function attemptUpdateMediationPlan (data) {
  return requests.updateMediationPlan(data)
    .then(response => {
      return response
    })
}

function attemptToggleActiveMediationPlan (id) {
  return requests.toggleActiveMediationPlan(id)
    .then(response => {
      return response.data
    })
}

function attemptRemoveMediationPlan (id) {
  return requests.removeMediationPlan(id)
    .then(response => {
      return response.data
    })
}

function attemptGetFilterList (pagination) {
  return requests.getFilterList(pagination)
    .then(filtersList => {
      return {
        data: filtersList.data.data,
        firstPage: filtersList.data.firstPage,
        lastPage: filtersList.data.lastPage,
        nextPage: filtersList.data.nextPage,
        previousPage: filtersList.data.previousPage,
        actualPage: filtersList.data.actualPage,
        total: filtersList.data.total
      }
    })
}

function attemptGetSolutionList (pagination) {
  return requests.getSolutionList(pagination)
    .then(solutionsList => {
      return {
        data: solutionsList.data.data,
        firstPage: solutionsList.data.firstPage,
        lastPage: solutionsList.data.lastPage,
        nextPage: solutionsList.data.nextPage,
        previousPage: solutionsList.data.previousPage,
        total: solutionsList.data.total
      }
    })
}

function attemptGetShowcaseLearningPathList (pagination) {
  return requests.getShowcaseLearningPathList(pagination)
    .then(learningPathsList => {
      return {
        data: learningPathsList.data.data,
        firstPage: learningPathsList.data.firstPage,
        lastPage: learningPathsList.data.lastPage,
        nextPage: learningPathsList.data.nextPage,
        previousPage: learningPathsList.data.previousPage,
        total: learningPathsList.data.total
      }
    })
}

function attemptGetLogList (payload) {
  return requests.getLogList(payload)
    .then(response => {
      return response.data
    })
}

export const services = {
  attemptGetMediationPlanList,
  attemptGetMediationPlan,
  attemptCreateMediationPlan,
  attemptUpdateMediationPlan,
  attemptToggleActiveMediationPlan,
  attemptRemoveMediationPlan,
  attemptGetFilterList,
  attemptGetSolutionList,
  attemptGetShowcaseLearningPathList,
  attemptGetLogList
}
